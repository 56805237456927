.Reviews {
}

.ReviewsHeader {
  max-width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;

  text-align: center;
  font-size: 2.5rem;
}

.ReviewsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 3rem;
  gap: 2rem;
}

.review {
  max-width: 24rem;
}
