.RespoPriceCategoryMain {
  display: flex;
  flex-direction: column;

  align-items: center;
}
.RespoTags {
  display: flex;
  justify-content: space-evenly;
}

.RespoPriceCategoryContainer {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  /* align-items: baseline; */
  gap: 3rem;
}
.RespoPriceCategory {
  /* padding: 2rem; */
  max-height: 60rem;
  border: solid rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.RespoPriceCategoryCategoryButton {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  min-height: 10rem;

  flex-direction: column;
}
.RespoPriceCategoryCheck {
  padding-top: 1rem;
  display: flex;
  gap: 1.5rem;

  flex-direction: column;
}

.RespoPriceCategoryCheckSvg {
  display: flex;
  align-items: center;
  height: 10rem;
  flex-direction: column;
}
.RespoPriceCategoryPrice {
  display: flex;
  align-items: center;
}
.RespoPriceCategoryPriceMoney {
  /* border: solid black; */
  display: flex;
  align-items: center;
}
.moneySign {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2.6rem;
  font-family: "Roboto", sans-serif;
}
.moneySign > span {
  font-size: 1rem;
}
.pricetag {
  font-family: "Roboto", sans-serif;

  font-size: 4rem;
}

/* CSS */

/* CSS */
.buttonPrice {
  appearance: button;
  backface-visibility: hidden;
  background-color: #405cf5;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.1) 0 2px 5px 0, rgba(0, 0, 0, 0.07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue",
    Ubuntu, sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all 0.2s, box-shadow 0.08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.buttonPrice:disabled {
  cursor: default;
}

.buttonPrice:focus {
  box-shadow: rgba(50, 50, 93, 0.1) 0 0 0 1px inset,
    rgba(50, 50, 93, 0.2) 0 6px 15px 0, rgba(0, 0, 0, 0.1) 0 2px 2px 0,
    rgba(50, 151, 211, 0.3) 0 0 0 4px;
}
.checkadnCommnets {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.pricetagMonth {
  /* border: solid black; */
  padding-top: 1.7rem;
  padding-left: 0.2rem;
}
.BestValueBannerContanier {
  display: flex;
  width: 100%;
  background-color: #405cf5;

  color: #fff;
  /* border-radius: 0.5rem; */
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  justify-content: center;
  align-items: center;
}
.BestValueBanner {
  /* position: relative;
  left: 9.1rem;
  top: -1.2rem;
  transform: rotate(50deg); */
  /* background-color: red; */
  /* height: 2.5rem; */
}
.RespoPriceCategoryCategory {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  font-size: 0.9rem;
  width: 20rem;
}
