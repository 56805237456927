.RespoBanner {
  background-image: url("../../img/bubbleimg.png");
  min-height: 35vh;
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 1rem;
  align-items: center;
  gap: 2rem;
}

.RespoBanner > h1 {
  font-family: "Roboto", sans-serif;
  margin: 0;

  max-width: 100vh;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.RespoBanner > P {
  max-width: 100vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #ffffff;
}
@media (width < 800px) {
  .RespoBanner {
    align-items: center;
  }
}
