.TabsComponent {
  height: 100%;

  max-width: 100%;
  margin: 0;
  padding: 0;
}
.TabsComponentImg {
  max-width: 100%;
}
