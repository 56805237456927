.HomePage {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 7rem;
}
.HomePage_VideoSection_video {
  display: flex;
  flex-direction: column;
  gap: 7rem;
}
.HomePageTitleContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.HomePageTitleHeaderContainer {
  display: flex;
  justify-content: center;
}

.HomePageTitleHeader {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
}
/* media */

.HomePageTitleParagraphContainer {
  display: flex;
  justify-content: center;
}

.HomePageTitleParagraphContainer > p {
  font-size: 2rem;
  display: flex;
  font-family: "Roboto Condensed", sans-serif;

  flex-direction: column;
}
.HomePageTitleParagraphContainer > p > span {
  display: flex;
  justify-content: center;
}
.HomePageTitleContainerButton {
  display: flex;
  justify-content: center;
}
/* 544 */

.HomePage_Section_tabs {
  display: flex;
  margin-top: 4rem;

  justify-content: space-evenly;
}

.HomePage_Section {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  gap: 1rem;
}

.HomePage_Section_header {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}

.HomePage_Section_Paragraph {
  font-size: 1.5rem;
}

.HomePage_Tabs {
  max-width: 50%;
}
.HomePage_Section_button {
  padding-top: 1rem;
}
@media (width < 800px) {
  .HomePageTitleParagraphContainer > p {
    font-size: 1.8rem;

    min-width: 92vw;
  }
  .HomePage_Section_tabs {
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: 4rem;
    /* border: solid; */
  }
  .HomePage_Section {
    flex-grow: 1;
    min-width: 92vw;
    gap: 1rem;
  }

  .HomePage_Tabs {
    min-width: 92vw;
  }
  .HomePageTitleHeader {
    display: flex;
    justify-content: center;
    font-size: 2.4rem;
    text-align: center;
    min-width: 82vw;
  }
  .PageEnding {
    display: flex;
  }
  .HomePage_Section_button {
    display: flex;
    justify-content: center;
  }
  .HomePage_Section_header {
    text-align: center;
  }
  .HomePage_Section_Paragraph {
    text-align: center;
  }
  .HomePageTitleParagraphContainer {
    text-align: center;
  }
  .HomePage_VideoSection_Paragraph {
    text-align: center;
  }
  .HomePage_VideoSection_video {
    align-items: center;
  }
}

.HomePage_VideoSection {
  display: flex;
  justify-content: center;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 3rem;
}

.HomePage_VideoSection_Paragraph {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 100;
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
}

.HomePage_VideoSection_Paragraph > span {
  display: flex;
  justify-content: center;
}

.HomePage_VideoSection_span1 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 100;
  font-size: 2rem;
}

.HomePage_VideoSection_span2 {
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
}
.HomePage_VideoSection_span3 {
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 100;
  font-size: 2rem;
}
.HomePage_video_container {
  width: 30rem;
}
.HomePage_video_container {
  border: solid;
  min-width: 100%;
}

.HomePage_All_Video {
  width: 100%;
  display: flex;
  justify-content: center;
}

.HomePage_banner {
  padding-top: 4rem;
}

.HomePageReviews {
  display: flex;
  justify-content: center;
}
.HomePageReviews {
  padding-top: 4rem;
}
.PageEnding {
  padding-top: 4rem;
}
