p,
h1,
body {
  margin: 0;
  padding: 0;
  width: auto;
  /* background-color: #f8f6f6; */
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (width < 374px) {
  .App {
    align-items: center;
  }
  .AppContainer {
    max-width: 70vh;
  }
}

.AppContainer {
  padding-top: 1rem;
  padding-bottom: 3rem;
  display: flex;
  max-width: 74rem;
  flex-direction: column;
  /* flex-grow: 1; */
}

ul {
  list-style-type: none;
}

.Footer {
  min-width: 100%;
}

@media (width < 800px) {
  .Footer {
    width: 90vw;
    padding: 0;
  }
}

a {
  text-decoration: none;
  color: black;
}
