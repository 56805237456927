.PageEnding {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.PageEndingHeader > header {
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  text-align: center;
  font-size: 3.5rem;
}
.PageEndingParaGraph {
  display: flex;
  justify-content: center;
}
.PageEndingParaGraph > p {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  text-align: center;
  display: flex;
  font-family: "Roboto Condensed", sans-serif;
}
.PageEndingButton {
  display: flex;
  justify-content: center;
}
